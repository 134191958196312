import decode from "jwt-decode";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import EnvVars from "../helpers/EnvVars";
import { RealUser, User } from "./Interfaces";
import DBService from "./DBService";
import { RouteComponentProps } from "react-router-dom";
import { isStrictlyDefined } from "../helpers/Helpers";
import { DroopleCache } from "../helpers/DroopleCache";
import {
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

/**
 * https://hptechblogs.com/using-json-web-token-react/
 */
export default class AuthService {
  private static ALLOW_USER_CREATION = false;
  private static currentUser: RealUser | undefined = undefined;

  private static localStorageFallback: {
    id_token?: string;
    refresh_token?: string;
    invitation_key?: string;
    invitation_client_id?: number;
    custom_client_id?: string;
  } = {
    id_token: undefined,
  };

  static async getLoginUrlForEmail(email: string) {
    try {
      const fetchUrl = `${EnvVars.API_GATEWAY_URL}/auth/login_url?email=${email}`;

      const resp = await fetch(fetchUrl, {
        method: "GET",
      });

      const respBody = await resp.json();
      if (respBody.data) {
        return respBody.data;
      }
    } catch (e) {
      // Do nothing in this case. We want to keep people on the normal flow if anything goes wrong on the SSO side
    }

    return null;
  }

  static async exchangeTokens(
    clientId: string,
    authCode: string = "",
    refreshToken: string = ""
  ) {
    try {
      const params = new URLSearchParams({
        client_id: clientId,
      });
      if (authCode) {
        params.set("code", authCode);
      } else if (refreshToken) {
        params.set("refresh_token", refreshToken);
      } else {
        console.error(
          "Neither authorization code nor refresh token were present"
        );
        return null;
      }
      const fetchUrl = `${
        EnvVars.API_GATEWAY_URL
      }/auth/tokens?${params.toString()}`;

      const resp = await fetch(fetchUrl, {
        method: "GET",
      });

      const respBody = await resp.json();
      if (respBody.data) {
        return respBody.data;
      }
    } catch (e) {
      console.error("Could not get tokens from authorization code. Error: ", e);
      return null;
    }
    return null;
  }

  static loggedIn() {
    // Checks if there is a saved token and it's still valid
    const id_token = AuthService.getIdToken(); // Getting token from localstorage
    if (!id_token) {
      return false;
    }
    const token: { exp: number } = decode(id_token);
    return !!token && !AuthService.isTokenExpired(token);
  }

  static refreshTokenIfNeeded() {
    // Checks if there is a saved token and it's still valid
    const id_token = AuthService.getIdToken(); // Getting token from localstorage
    if (id_token) {
      const token: { exp: number } = decode(id_token);
      if (token && AuthService.isTokenExpired(token)) {
        return AuthService.updateIdToken();
      }
    }
  }
  static isTokenExpired(token: { exp: number }) {
    try {
      return token.exp < Date.now() / 1000;
    } catch (err: any) {
      return false;
    }
  }

  static setIdToken(idToken: AmazonCognitoIdentity.CognitoIdToken | string) {
    const token = typeof idToken === "string" ? idToken : idToken.getJwtToken();
    // Saves user token to localStorage
    try {
      localStorage.setItem("id_token", token);
    } catch (e) {
      console.warn(
        "Cannot save credentials to local storage. Using temporary variable instead."
      );
      AuthService.localStorageFallback.id_token = token;
    }
  }
  static setRefreshToken(
    refreshToken: AmazonCognitoIdentity.CognitoRefreshToken | string
  ) {
    const token =
      typeof refreshToken === "string" ? refreshToken : refreshToken.getToken();
    // Saves user token to localStorage
    try {
      localStorage.setItem("refresh_token", token);
    } catch (e) {
      console.warn(
        "Cannot save credentials to local storage. Using temporary variable instead."
      );
      AuthService.localStorageFallback.refresh_token = token;
    }
  }
  static setCustomClientId(clientId: string) {
    try {
      localStorage.setItem("custom_client_id", clientId);
    } catch (e) {
      console.warn(
        "Cannot save credentials to local storage. Using temporary variable instead."
      );
      AuthService.localStorageFallback.custom_client_id = clientId;
    }
  }

  static getIdToken(): string {
    // Retrieves the user token from localStorage
    try {
      return localStorage.getItem("id_token") ?? "";
    } catch (e) {
      console.warn(
        "Cannot read credentials from local storage. Using temporary variable instead."
      );
      return AuthService.localStorageFallback.id_token ?? "";
    }
  }
  static getRefreshToken(): string {
    // Retrieves the user token from localStorage
    try {
      return localStorage.getItem("refresh_token") ?? "";
    } catch (e) {
      console.warn(
        "Cannot read credentials from local storage. Using temporary variable instead."
      );
      return AuthService.localStorageFallback.refresh_token ?? "";
    }
  }
  static getCustomClientId(): string {
    try {
      return localStorage.getItem("custom_client_id") ?? "";
    } catch (e) {
      console.warn(
        "Cannot read credentials from local storage. Using temporary variable instead."
      );
      return AuthService.localStorageFallback.custom_client_id ?? "";
    }
  }

  static async updateIdToken() {
    const customClientId = AuthService.getCustomClientId();
    if (customClientId) {
      const refreshToken = AuthService.getRefreshToken();
      try {
        const tokens = await AuthService.exchangeTokens(
          customClientId,
          "",
          refreshToken
        );
        if (tokens && tokens.idToken) {
          AuthService.setIdToken(tokens.idToken);
        }
      } catch (e) {
        console.error("Could not refresh token. Error: ", e);
        AuthService.logout();
        window.location.reload();
      }
    } else {
      const refreshToken = new CognitoRefreshToken({
        RefreshToken: AuthService.getRefreshToken(),
      });
      const cognitoUser = AuthService.getCognitoUser(
        AuthService.currentUser?.email ?? ""
      );
      return new Promise<void>((resolve, reject) =>
        cognitoUser.refreshSession(
          refreshToken,
          (err, session: CognitoUserSession) => {
            if (err) {
              AuthService.logout();
              // This is a trick to redirect to logout without modifying DBService
              window.location.reload();
              reject();
            } else {
              console.log("User tokens refreshed");
              AuthService.setIdToken(session.getIdToken());
              resolve();
            }
          }
        )
      );
    }
  }
  static logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem("id_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("custom_client_id");
    DroopleCache.invalidate();
    AuthService.localStorageFallback.id_token = undefined;
    AuthService.localStorageFallback.custom_client_id = undefined;
    if (AuthService.currentUser) AuthService.currentUser = undefined;

    const currentUser = AuthService.userPool.getCurrentUser();
    if (currentUser) currentUser.signOut();
  }

  static async getProfile(): Promise<RealUser> {
    if (AuthService.currentUser && AuthService.loggedIn())
      return Promise.resolve(AuthService.currentUser);
    else {
      const id_token = AuthService.getIdToken();
      if (!id_token) throw Error("User not logged in");
      const decodedIdToken: any = decode(id_token);
      let email = decodedIdToken.email?.toLowerCase();
      if (!email) {
        const identities = decodedIdToken.identities ?? [];
        for (const identity of identities) {
          if (identity.providerType === "SAML" && identity.userId) {
            email = identity.userId;
            break;
          }
        }
      }
      console.log(decodedIdToken);
      const username = decodedIdToken["cognito:username"];
      return DBService.getUserByEmail(email)
        .then((res) => {
          if (res.data && res.data) {
            const user = res.data;
            AuthService.currentUser = user;
            return user;
          } else {
            throw new Error(
              "User is not provisioned in the system. Please contact your administrator to receive access."
            );
          }
        })
        .catch(async (err) => {
          // user does not exist in our system, we need to create it.
          if (err.message !== "Network Error" && email && username) {
            if (AuthService.hasInvitation()) {
              console.log(
                "User does not exist in Droople database. Creating it."
              );
              const user: User = {
                email: email,
                username: username,
                params: {},
                client: {
                  id: AuthService.clientIdFromInvitation(),
                },
                role: "ADMIN",
                notifications: true,
                volume_unit: "liter",
                temperature_unit: "degree_celsius",
                pressure_unit: "bar",
                mass_unit: "kg",
              };
              const newUser = (await DBService.postAllowAnonymous(
                user,
                "users?invitation_key=" + AuthService.getInvitationKey()
              )) as RealUser;
              AuthService.currentUser = newUser;
              AuthService.clearInvitationFromCache();
              return newUser;
            } else if (AuthService.ALLOW_USER_CREATION) {
              console.log(
                "User does not exist in Droople database. Creating it."
              );
              const user: User = {
                email: email,
                username: username,
                params: {},
                client: {
                  name: email,
                },
                notifications: true,
                role: "ADMIN",
                volume_unit: "liter",
                temperature_unit: "degree_celsius",
                pressure_unit: "bar",
                mass_unit: "kg",
              };
              const newUser = (await DBService.postAllowAnonymous(
                user,
                "users"
              )) as RealUser;
              AuthService.currentUser = newUser;
              return newUser;
            } else throw err;
          } else throw err;
        });
    }
  }
  static clientIdFromInvitation(): number | undefined {
    try {
      const clientId = localStorage.getItem("invitation_client_id");
      if (isStrictlyDefined(clientId)) {
        return Number(clientId);
      } else {
        return undefined;
      }
    } catch (e) {
      console.warn(
        "Cannot read invitation data from local storage. Using temporary variable instead."
      );
      return AuthService.localStorageFallback.invitation_client_id;
    }
  }

  static saveInvitationToCache(key: string, client_id: number) {
    try {
      localStorage.setItem("invitation_key", key);
      localStorage.setItem("invitation_client_id", client_id.toString());
    } catch (e) {
      console.warn(
        "Cannot save invitation data to local storage. Using temporary variable instead."
      );
      AuthService.localStorageFallback.invitation_key = key;
      AuthService.localStorageFallback.invitation_client_id = client_id;
    }
  }

  static clearInvitationFromCache() {
    try {
      localStorage.removeItem("invitation_key");
      localStorage.removeItem("invitation_client_id");
    } catch (e) {
      console.warn(e);
    }
    AuthService.localStorageFallback.invitation_key = undefined;
    AuthService.localStorageFallback.invitation_client_id = undefined;
  }

  static hasInvitation() {
    return !!AuthService.getInvitationKey();
  }

  static getInvitationKey() {
    try {
      return localStorage.getItem("invitation_key") || "";
    } catch (e) {
      console.warn(
        "Cannot read invitation data from local storage. Using temporary variable instead."
      );
      return AuthService.localStorageFallback.invitation_key || "";
    }
  }

  ///////////////////////////////////////////////
  static poolData: AmazonCognitoIdentity.ICognitoUserPoolData = {
    UserPoolId: EnvVars.AWS_COGNITO_USER_POOL_ID,
    ClientId: EnvVars.AWS_COGNITO_CLIENT_ID,
  };

  static userPool = new AmazonCognitoIdentity.CognitoUserPool(
    AuthService.poolData
  );

  // Initializing important variables
  constructor() {
    if (AuthService.userPool.getCurrentUser()) {
      AuthService._fetchCurrentAuthToken();
    }
  }

  /*
   * Cognito User Pool functions
   */

  static _fetchCurrentAuthToken(): Promise<AmazonCognitoIdentity.CognitoIdToken> {
    return new Promise((resolve, reject) => {
      var cognitoUser = AuthService.userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession(function sessionCallback(
          err: Error,
          session: any
        ) {
          if (err) {
            reject(err);
          } else if (!session.isValid()) {
            reject(new Error("Invalid Session"));
          } else {
            const cognitoToken = session.getIdToken();
            AuthService.setIdToken(cognitoToken);
            AuthService.setRefreshToken(session.getRefreshToken());
            resolve(cognitoToken);
          }
        });
      } else {
        reject(new Error("No current Cognito user"));
      }
    });
  }

  static register(
    name: string,
    email: string,
    password: string,
    onSuccess: any,
    onFailure: any
  ) {
    var dataEmail = {
      Name: "email",
      Value: email.toLowerCase(),
    };
    var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
      dataEmail
    );

    var dataName = {
      Name: "name",
      Value: name,
    };
    var attributeName = new AmazonCognitoIdentity.CognitoUserAttribute(
      dataName
    );

    AuthService.userPool.signUp(
      AuthService.toUsername(email),
      password,
      [attributeEmail, attributeName],
      [],
      (err, res) => {
        if (!err) {
          onSuccess(res);
        } else {
          onFailure(err);
        }
      }
    );
  }

  static signin(
    email: string,
    password: string,
    onSuccess: any,
    onFailure: any
  ) {
    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      {
        Username: AuthService.toUsername(email),
        Password: password,
      }
    );

    var cognitoUser = AuthService.createCognitoUser(email);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (res: AmazonCognitoIdentity.CognitoUserSession) => {
        const session = res;
        AuthService.setIdToken(session.getIdToken());
        AuthService.setRefreshToken(session.getRefreshToken()); // Setting the tokens in localStorage
        AuthService.getProfile()
          .then((profile) => {
            if (!profile.client) {
              const error: any = new Error(
                "User profile doesn't have a client"
              );
              error.code = "ProfileWithoutClient";
              throw error;
            }
            if (profile.disabled) {
              const error: any = new Error("User's disabled");
              error.code = "ProfileDisabled";
              throw error;
            }
            onSuccess(session);
          })
          .catch((err) => {
            console.error(err);
            AuthService.logout();
            onFailure(err);
          });
      },
      onFailure: onFailure,
    });
  }

  static verify(email: string, code: string, onSuccess: any, onFailure: any) {
    if (!email) {
      onFailure(Error("Email missing."));
      return;
    }
    AuthService.createCognitoUser(email).confirmRegistration(
      code,
      true,
      function confirmCallback(err, result) {
        if (!err) {
          onSuccess(result);
        } else {
          onFailure(err);
        }
      }
    );
  }

  static resendVerificationCode(email: string, onSuccess: any, onFailure: any) {
    AuthService.createCognitoUser(email).resendConfirmationCode(
      function confirmCallback(err, result) {
        if (!err) {
          onSuccess(result);
        } else {
          onFailure(err);
        }
      }
    );
  }

  static forgotPassword(email: string, onSuccess: any, onFailure: any) {
    AuthService.createCognitoUser(email).forgotPassword({
      onSuccess,
      onFailure,
    });
  }

  static confirmPassword(
    email: string,
    verificationCode: string,
    newPassword: string,
    onSuccess: any,
    onFailure: any
  ) {
    AuthService.createCognitoUser(email).confirmPassword(
      verificationCode,
      newPassword,
      { onSuccess, onFailure }
    );
  }

  static createCognitoUser(email: string) {
    return new AmazonCognitoIdentity.CognitoUser({
      Username: AuthService.toUsername(email),
      Pool: AuthService.userPool,
    });
  }

  static toUsername(email: string) {
    return email.replace("@", "-at-").toLowerCase();
  }

  static redirectToLogin(props: RouteComponentProps) {
    AuthService.logout();
    const currentPath = props.location.pathname + props.location.search;
    props.history.replace(
      currentPath === "/"
        ? "/login"
        : "/login?redirect=" + encodeURIComponent(currentPath)
    );
  }

  static isAuthenticated() {
    return !!AuthService.getIdToken();
  }
  static getCognitoUser(email: string) {
    const userPool = new CognitoUserPool(AuthService.poolData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    return new CognitoUser(userData);
  }
}
